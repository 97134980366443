@import "Config/ThemeVariables.less";

.custom-button-left-radius {
  border-radius: 2.375rem 0rem 0rem 2.375rem !important;
}
.custom-button-right-radius {
  border-radius: 0rem 2.375rem 2.375rem 0rem !important;
}
.custom-button-left-radius {
  border-radius: 2.375rem 0rem 0rem 2.375rem !important;
}
.forney-vault {
  .custom-button-left {
    border-radius: 0.05rem 0rem 0rem 0.05rem;
  }
  .custom-button-right {
    border-radius: 0.05rem 0rem 0rem 0.05rem;
  }
}
.activeButton {
  color: #1890ff;
  border: 1px solid var(--primary-6, #1890ff);
  background: var(--neutral-1, #fff);
  /* drop-shadow/button-secondary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}
.custom-border {
  border-radius: 0;
}
.textSpan {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ant-radio-wrapper span,
.textSpan span {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}
.ant-modal-footer button {
  border-radius: 100px !important;
  width: 100% !important;
}
.drawer-btn-child {
  // margin: 0px 5px;
}
.ant-checkbox-inner,
.ant-checkbox-disabled .ant-checkbox-inner {
  border: 1px solid @gray !important;
  border-radius: 2px;
}
.ant-modal-content {
  padding: 1rem !important;
  border-radius: 8px !important;
  border: 1px solid var(--neutral-5, #d9d9d9) !important;
  background: var(--theme-white, #fff);
  display: grid;
  align-content: space-between;

  .ant-select {
    width: 100%;
  }
  .addbutton {
    color: var(--day-break-blue-6, #1890ff);
  }
  .ant-modal-title {
    font-size: 20px !important;
  }
  .textSpan label {
    line-height: normal !important;
  }
  .process button {
    border-radius: var(--Primary-Radius, 2.375rem);
    border: 1px solid var(--primary-5, #40a9ff);
    background: var(--theme-secondary, #2597fe);
  }
}
.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.ant-drawer-content-wrapper {
  padding: 15px 0;
}
.related-item-drawer {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: -3px 0px 17px 0px rgba(183, 183, 183, 0.25);
}
.ant-drawer-header {
  padding: 28px 24px 9px !important;
}
.custom-prop {
  input,
  .ant-select-selector {
    border-radius: 100px;
    border: 1px solid @gray;
    background: @white;
  }
  .error,
  .error-class {
    color: @red-color;
    font-size: 12px;
    padding-left: 10px;
  }
  label {
    color: @modal-title-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .addbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-right: 0;
  }
  h4 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;

    line-height: 2rem;
    text-transform: capitalize;
  }
  button {
    border-radius: 100px !important;
  }
  .ant-radio-wrapper {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 157.143% */
  }
}
.ant-select-selection-overflow {
  margin-top: 8px;
  margin-left: 26px;
}
.processModal {
  .ant-modal-content {
    display: grid;
    gap: 25px;
  }
  .ant-modal-header {
    margin-bottom: 0px !important;
  }
  .ant-modal-footer {
    margin: 0px !important;
  }
  .stepRadio {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .stepsCrud {
    display: flex;
    flex-direction: column;
    gap: 25px;
    textarea {
      height: 84px !important;
      border-radius: 12px;
    }
    .formInfoText {
      color: var(--neutral-7, #8c8c8c);
      /* Body/regular */
      // font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding: 5px;
    }
  }
}
.drag-phase-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .reorderPhase {
    padding: 12px 8px;
    border-radius: 20px;
    background: #efefef;
    align-self: stretch;
    display: grid;
    gap: 16px;
  }
  .drag-phase {
    display: flex;
    padding: 0 10px;
    align-items: center;
    gap: 4px;
    border-radius: 140px;
    background: #fafafa;
    font-weight: 700;
  }
  .drag-phase1 {
    display: flex;
    gap: 4px;

    padding: 0 5px;
  }
  .drap-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }
  .drag-list {
    padding: 4px 13px;
    border-radius: 38px;
    border: 1px solid var(--neutral-5, #d9d9d9);
    background: var(--neutral-1, #fff);
    align-self: stretch;
  }
  .drag-list div {
    display: flex;
    align-items: center;
  }
}
.saveBtn {
  border-radius: var(--Primary-Radius, 38px);
  border: 1px solid var(--primary-5, #40a9ff);
  background: var(--theme-secondary, #2597fe);
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  margin-top: 18px;
  width: 100%;
}
.copyButton {
  border: none;
  margin-left: 10px !important;
  color: #ff7a45 !important;
}
.copyButton:hover {
  color: #fe9870 !important;
}
.LoaderClass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.Cascader {
  .ant-cascader {
    margin-bottom: 5px;
  }
}
