//deafult screen

:root {
  ---header-font: 24px;
  ---sub-header-font: 20px;
  ---p-font: 15px;
  ---button-font: 16px;
  ---table-font: 14px;
  ---tag-font: 12px;
  ---input-font: 14px;
  ---title-font: 18px;
}

// mobile screen
@media screen and (min-width: 300px) and (max-width: 780px) {
  :root {
    ---header-font: 20px;
    ---sub-header-font: 16px;
    ---p-font: 13px;
    ---button-font: 14px;
    ---table-font: 12px;
    ---tag-font: 10px;
    ---input-font: 12px;
    ---title-font: 16px;
  }
}

// tablet screen
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  :root {
    ---header-font: 22px;
    ---sub-header-font: 18px;
    ---p-font: 14px;
    ---button-font: 14px;
    ---table-font: 12px;
    ---tag-font: 10px;
    ---input-font: 13px;
    ---title-font: 17px;
  }
}

// large screen
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  :root {
    ---header-font: 35px;
    ---sub-header-font: 24px;
    ---p-font: 20px;
    ---button-font: 22px;
    ---table-font: 17px;
    ---tag-font: 15px;
    ---input-font: 18px;
    ---title-font: 20px;
  }
}

.header-font {
  font-size: var(---header-font) !important;
}
.sub-header-font {
  font-size: var(---sub-header-font) !important;
}
.p {
  font-size: var(---p-font) !important;
}
.button-font {
  font-size: var(---button-font) !important;
}
.table-font {
  font-size: var(---table-font) !important;
}
.tag-font {
  font-size: var(---tag-font) !important;
}
.input-font {
  font-size: var(---input-font) !important;
}
.title-font {
  font-size: var(---title-font) !important;
}

