@import "Config/ThemeVariables.less";

.layout {
  height: 100vh;
  gap: 7px;
  padding: 15px 0px;
  background-color: @sidebar-color !important;

  .logo {
    height: 29.2px;
    transition: 0.5s;
  }

  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px 24px 10px;
    transition: 0.5s;

    button {
      transition: 0.5s;
      padding-right: 1px;
    }
  }

  .desktop-sidebar {
    display: block;
    overflow: auto;
  }

  .ant-layout {
    background-color: @sidebar-color;
    border-top-left-radius: 38px;
    border-bottom-left-radius: 38px;
    overflow: hidden;
  }

  .sidebar {
    position: relative;
    height: 100vh !important;
    background-color: @sidebar-color !important;
    // background-image: url('../Assets/sidebarIcons/sidebar-bottom.svg') !important;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 253px;
    padding: 15px 0px 15px 19px;

    .searchbar {
      margin: 10px !important;

      input,
      button {
        background: @sidebar-search-bg;
        border-color: @sidebar-search-bg !important;
        color: @white !important;
        height: 32px;
      }

      button {
        width: 40px;
      }

      input {
        &::placeholder {
          color: @search-placeholder-color;
        }
      }
    }

    .ant-menu-root {
      background-color: transparent;
      height: calc(100vh - 245px);
      overflow-y: auto;
      overflow-x: hidden;
      transition: 0.5s;
      padding-right: 12px;
      gap: 16px;

      li {
        // min-height: 45px;
        border-radius: 0;
        width: 100%;
        // margin-bottom: 10px;
        margin-inline: 0;
        height: auto;
        line-height: normal;
        padding: 11px 15px !important;

        &:first-of-type {
          margin-top: 18px;
        }

        a {
          font-weight: 400;
          color: @search-placeholder-color;
          font-size: 14px;
          line-height: 26px;
        }
      }

      .ant-menu-item .ant-menu-inline {
        padding: 6.4px 20px !important;
      }

      .ant-menu-item:hover {
        background-color: #515151 !important;
      }

      .ant-menu-item-selected {
        a {
          font-weight: 600 !important;
          color: @white;
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-item:hover {
        background-color: #515151 !important;
      }

      .ant-menu-item {
        margin: 8px;
        padding-left: 12px !important;

        .ant-menu-title-content {
          padding: 0px;
        }
      }

      .ant-menu-submenu-title {
        gap: 11px;
        height: auto;
        padding-inline: 0 !important;

        .sidebar-icon {
          width: 14px;
          height: 14px;
          // margin-right: 11px;
          font-size: 19px;
        }

        span {
          font-weight: 400;
          color: @search-placeholder-color;
        }

        span.-title-content {
          margin-left: 0;
          line-height: 26px;
        }

        span.ant-icon {
          margin-right: 8px;
          font-size: 18px;
        }
      }

      .ant-menu-sub {
        background: transparent !important;
      }
    }

    .ant-menu-dark .ant-menu-item {
      border-left: 2px solid transparent;
      border-radius: 100px;
    }

    .ant-menu-dark .ant-menu-item-selected {
      background: @secondary-blue-btn;
    }

    .sidebar-icon {
      width: 14px;
      height: 14px;
      font-size: 19px;
    }

    .help-btn {
      position: absolute;
      bottom: 70px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 50%;
      height: 51px;
      border-radius: 0 100px 100px 0;
      font-size: 16px;
    }

    .message-wrapper {
      position: absolute;
      bottom: 30px;
      left: 19px;
    }

    .sidebar-message-icon {
      width: 24px;
      height: 24px;
    }

    .message-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px 8px;
      border-radius: 70px;
    }
  }

  .ant-layout-sider-collapsed {
    .logo-wrapper {
      margin: 0px;
      padding-inline: 0;
      transition: 0.5s;

      .logo {
        height: 28px;
        transition: 0.5s;
      }

      button {
        padding-top: 2px;
        transition: 0.5s;
        transform: rotate(180deg);
      }
    }

    .ant-menu-item {
      margin: 0px !important;
    }

    .sidebar-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        min-width: 16px;


        margin: 0;
      }
    }
  }

  .main-haeder {
    background: @main-layout-bg;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
    padding: 0 25px;
    height: auto;

    >div {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      padding: 20px 0 16px;
    }

    .ant-dropdown-trigger {
      display: flex;
      align-items: center;
      gap: 15px;

      .wrapper {
        display: flex;
        flex-direction: column;
        width: fit-content;

        .name {
          line-height: 21px;
          font-size: 14px;
          font-weight: 500;
          color: @text-dark;
        }

        .email {
          line-height: 1;
          color: @light-gray;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .fetch-button-style {
    margin: 10px;
  }

  .title {
    position: relative;
    width: fit-content;
    font-size: 30px;
    line-height: 32px;
    text-transform: capitalize;

    .question-icon {
      font-size: 16px;
      margin-left: 5px;
      color: #1881c5;
    }
  }

  .main {
    margin: 0;
    height: calc(100vh - 64px - 24px - 24px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: @main-layout-bg;
    padding: 0 10px;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;

    &>div {
      padding: 20px;

      &.background-white {
        padding: 0;
      }
    }
  }

  .icon-size {
    font-size: 24px !important;
  }

  .ant-drawer-body {
    padding: 0;
    height: 100vh;
    overflow: hidden;

    .sidebar {
      background-size: contain;
      height: 100vh;
      overflow: hidden;

      .help-btn {
        bottom: 118px;
      }

      .ant-menu-root {
        height: calc(100vh - 285px);
      }

      ul {
        li {
          margin: 10px 0;
          min-height: 50px;
          font-size: 16px;
        }
      }

      .blue-border-btn {
        background: rgba(255, 255, 255, 0.23);
        box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1);
        width: 80%;
        margin: 10px auto 0;
        color: @white;
        gap: 7px;
      }

      .menu-title {
        width: 80%;
        margin: 30px auto 5px;
        color: rgba(255, 255, 255, 0.57);
      }
    }
  }

  .ant-drawer-header {
    border: 0;
    background: @sidebar-color;
    padding: 0 31px;

    .logo {
      margin: 16px 16px 16px 0;
      height: 28px;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
  }

  .ant-drawer-title {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .ant-drawer-close {
    color: @white;
    margin-top: 5px;
    font-size: 23px;

    &:hover {
      color: @white;
    }

    svg {
      stroke: @white;
      stroke-width: 40px;
    }
  }
}

.mobile-menu {
  display: none;
}

:where(.css-dev-only-do-not-override-g32wyo).ant-menu-inline-collapsed-tooltip a {
  color: @black-color !important;
}

.ant-menu-submenu-popup {
  ul {
    box-shadow: none !important;
    position: relative;

    .ant-menu-item {
      background: @sidebar-color !important;

      .ant-menu-title-content {
        a {
          color: @white !important;
        }
      }
    }
  }
}

.new {
  padding-right: 12px;
  margin: 0px 10px 20px auto;
  // min-height: 45px;
  border-radius: 0;
  width: 90%;
  font-weight: 600;
  color: @search-placeholder-color;
}

// custom styles
:where(.css-dev-only-do-not-override-15jctj0).ant-menu-inline .ant-menu-submenu-title {
  padding-inline: initial !important;
  margin: 0 !important;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: auto;
  line-height: initial;
}

.page-action-wrapper {
  margin-top: 15px;
  padding: 16px 8px 18px;
}

.ant-drawer-body .page-action-wrapper {
  padding-top: 0;
}

.dashboard-action-btn {
  background-color: transparent;
  height: auto;

  svg {
    fill: @dark-blue-btn;
  }

  &.svg-black {
    svg {
      fill: @black-color;
    }
  }
}

:where(.css-dev-only-do-not-override-15jctj0).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
:where(.css-dev-only-do-not-override-15jctj0).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  color: @dark-blue-btn;
  border-color: @dark-blue-btn;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-radio-button-wrapper:hover {
  color: @dark-blue-btn;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-btn-default:not( :disabled):not(.ant-btn-disabled):hover {
  color: @secondary-blue-btn;
  border-color: @secondary-blue-btn;

  span .dashboard-action-btn.svg-black svg {
    fill: @secondary-blue-btn;
  }
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-modal .ant-modal-footer:has(.ant-btn) {
  margin-top: 25px;
}

.layout .sidebar .ant-menu-inline-collapsed .ant-menu-submenu {
  padding: 0 !important;

  .ant-menu-submenu-title {
    padding-left: 11px !important;
    height: 40px;
    align-items: center;
  }
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-menu .ant-menu-item,
:where(.css-dev-only-do-not-override-1qbztl0).ant-menu .ant-menu-submenu-title {
  display: flex;
  width: calc(100% - -19px);
  font-size: 14px;
}



.layout .sidebar.ant-layout-sider-collapsed .ant-menu-root li:not(.ant-menu-submenu-vertical) {
  padding: 7px 8px !important;
  display: flex;
}


:where(.css-1qbztl0).ant-menu .ant-menu-submenu-arrow::before,
:where(.css-1qbztl0).ant-menu .ant-menu-submenu-arrow::after {
  right: -14px;
}