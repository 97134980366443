@import "Config/ThemeVariables.less";

.dashboard-table {
  .ant-table-cell,
  .ant-table-column-title {
    color: #262626 !important;
  }
  thead.ant-table-thead tr {
    border-radius: 10px !important;
  }
  .ant-table-tbody {
    .up-column {
      animation: up-highlight-animate-head 2s ease-in-out;
      transition: 0.1s;
    }
    .down-column {
      animation: down-highlight-animate-head 2s ease-in-out;
      transition: 0.1s;
    }
  }
}

@keyframes up-highlight-animate-head {
  0% {
    background: #a9d778;
  }
  100% {
    background: transparent;
  }
}
@keyframes down-highlight-animate-head {
  0% {
    background: #ee5735;
  }
  100% {
    background: transparent;
  }
}

.box-wrapper {
  // display: flex;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  .box {
    flex-grow: 1;
    flex-basis: 0px;
    height: 130px;
    display: flex;
    padding: 20px;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: @white-base;
    border: 1px solid @slider-dots-border-color;
    box-shadow: 0px 5px 20px rgba(23, 129, 197, 0.05);
    border-radius: 6px;
    h1 {
      color: @text-color;
      margin-bottom: 0;
      font-weight: bolder;
    }
    h5 {
      color: @text-color;
      font-size: 15px;
      margin-top: 0;
      font-weight: 600;
      margin-bottom: 0;
      width: 70%;
    }
  }
}
.abcss {
  visibility: hidden;
}
// .ant-progress-text {
//   display: none !important;
// }
