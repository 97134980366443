@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  max-height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100vw;
}

@import "Config/ThemeVariables.less";
@path-fonts: "./Assets/Fonts";

@font-face {
  font-family: "Inter";
  src: url("@{path-fonts}/Inter-Light.ttf");
  src: url("@{path-fonts}/Inter-Bold.ttf");
  src: url("@{path-fonts}/Inter-Medium.ttf");
  src: url("@{path-fonts}/Inter-Regular.ttf");
  src: url("@{path-fonts}/Inter-SemiBold.ttf");
}

.themeSwitch {
  position: absolute;
  top: 5%;
  left: 3%;
  z-index: 999;
}

.input,
.input:focus,
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  background: @white !important;
  background-color: @white !important;
  border: 1px solid @white !important;
  border-radius: 20px !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0px 0px 5px 0px rgba(194, 194, 194, 0.15);
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;

  &::placeholder {
    color: @placeholder-color;
  }
}

.ant-input-password {

  input,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;

    &::placeholder {
      color: #BFBFBF;
    }
  }
}

.background-white {
  border-radius: 10px !important;
}

.primary-btn {
  background: @blue-btn;
  font-weight: 600;
  box-shadow: none;
  color: @white;
  border-radius: 60px;
}

.dark-primary-btn {
  background: @dark-blue-btn;
  font-weight: 600;
  box-shadow: none;
  color: @white;
  border-radius: 60px;
}

.dark-danger-btn {
  background: #ff4d4f;
  font-weight: 600;
  box-shadow: none;
  color: @white;
  border-radius: 60px;
}

.white-btn {
  background-color: @white;
  border: 1px solid @text-color;
  border-radius: 4px;
  padding-inline: 25px;
  font-weight: 600;
}

.blue-border-btn {
  background: @white;
  color: @sidebar-color;
  border-color: @sidebar-color;
  font-weight: 600;
  height: auto;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 5px;
  }

  &:hover {
    background: @light-blue !important;
    color: @sidebar-color !important;
    border-color: @sidebar-color !important;
  }
}

.blue-btn {
  background: @primary;
  color: @white;
  border-color: @primary;
  font-weight: 600;
  height: auto;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 5px;
  }

  &:hover {
    background: @light-blue !important;
    color: @primary !important;
    border-color: @primary !important;
  }
}

.gray-btn {
  background-color: @gray-btn;
  color: @black-color;
  border-color: @gray-btn;
}

.share-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: @text-color;
  font-weight: 600;

  svg {
    color: @orange-btn;
  }
}

.text-left {
  text-align: left;
}

.text-black {
  color: @text-color !important;
}

.text-white {
  color: @white !important;
}

.text-gray {
  color: @light-gray !important;
}

.text-light-gray {
  color: @lightest-gray !important;
}

.text-orange {
  color: rgb(238, 108, 77) !important;
}

.text-primary {
  color: @primary !important;
}

.text-danger {
  color: #ff4d4f !important;
}

.hover-off {
  &:hover {
    background-color: transparent !important;
  }
}

.ant-checkbox-inner {
  border: 1px solid @primary !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.overflowX-hidden {
  overflow-x: hidden;
}

.capital-text {
  text-transform: capitalize;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.w-100 {
  width: 100%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-80px {
  width: 80px;
}

.w-100px {
  width: 100px;
}

.w-105px {
  width: 105px;
}

.w-165px {
  width: 165px;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-25 {
  height: 25px;
}

.h-40 {
  height: 40px;
}

.h-30 {
  height: 30px;
}

.h-35 {
  height: 35px;
}

.h-45 {
  height: 45px;
}

.h-120 {
  height: 100px !important;
}

.select-h-40 {
  .ant-select-selector {
    height: 40px !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    margin: auto;
  }
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 10px !important;
}

.p-17 {
  padding: 17px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-10 {
  padding-top: 20px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 44px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-auto {
  margin: auto !important;
}

.d-flex {
  display: flex;
}

.w-300 {
  width: 300px;
}

.w-200 {
  width: 200px;
}

.side-searchbar {
  position: relative;
  border-radius: 100px !important;
  background-color: #232527;
  border: none;
  padding: 3px 8px 3px 10px;
  cursor: pointer;

  input {
    background-color: #232527;
    color: #f8f8f8;
  }

  input::placeholder {
    color: #f8f8f8;
  }
}

.searchbar-k-icon {
  padding: 1px 7px;
  color: #c5c5c5;
  background-color: #343638;
  border-radius: 20px;
  cursor: pointer;
}

.ant-dropdown-menu {
  margin-top: 12px !important;
}

.searchbar {
  margin-bottom: 16px;

  input {
    position: relative;
    height: 32px;
    padding-left: 40px;
    border: 0.5px solid @border-color !important;
    border-radius: 100px !important;
    background-color: transparent;
  }

  .ant-input-group-addon {
    position: absolute;
    left: 0;
    top: 0;

    button {
      height: 32px;
      box-shadow: none;
      border-left: none;
      border-top: none;
      border-bottom: none;
      border-start-end-radius: 0 !important;
      border-end-end-radius: 0 !important;
      border-right: 0.5px solid @border-color !important;
      background-color: transparent !important;
      z-index: 1;
      font-size: 17px;

      .anticon svg {
        fill: #8C8C8C;
      }
    }
  }
}



// table css
.ant-table {
  background: transparent !important;

  a {
    color: inherit;
  }

  .ant-table-row-selected {
    .ant-table-cell {
      background: @table-row-selected !important;
    }
  }
}

// pagination css
.ant-pagination {
  gap: 5px;

  li {
    border: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
  }

  .ant-pagination-options {
    border: 0 !important;

    .ant-select-selector,
    input {
      border-radius: 0 !important;
    }
  }
}

// tag css
.ant-tag {
  border-radius: 100px;
  padding-inline: 15px;
  text-transform: capitalize;
  padding: 3px 14px;
  font-size: 12px;
  font-weight: 500;
}

.ant-tag.idea {
  background: @light-skyblue !important;
  border-color: @idea-card !important;
  color: @table-strip-color !important;
}

.ant-tag.project {
  background: @project-tag-bg !important;
  border-color: @dark-primary !important;
  color: @dark-primary !important;
}

.ant-tag.opportunity {
  background: @light-blue !important;
  border-color: @opportunity-card !important;
  color: @opportunity-card !important;
}

.file-tag {
  border-radius: 3px;
  background: @light-blue !important;
  border-color: @light-blue !important;
  color: @primary !important;
}

.capitalize-text {
  text-transform: capitalize;
}

// tooltip css
.ant-tooltip {
  .ant-tooltip-content {
    border-radius: 5px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09);

    .ant-tooltip-arrow-content {
      box-shadow: 0.5px 0.5px 0.5px 0.5px rgb(0 0 0) !important;
      --antd-arrow-background-color: @white !important;
    }

    .ant-tooltip-inner,
    a {
      color: @text-color !important;
      font-weight: 500;
      border-radius: 0 !important;
      background-color: @white;
      border-radius: 5px !important;
    }
  }

  .ant-tooltip-arrow:before {
    background: @white;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;

  &-thumb {
    background: #E0E0E0;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    width: 10px;
    transition: all .3s ease;

    &:hover {
      background: #B9B9B9;
      border-color: #6A6A6A;
    }
  }

  &-track {
    background: #F2F2F2;
    width: 6px;
    border-radius: 5px;
  }
}

.layout .sidebar {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    &-thumb {
      background: #7F7F7F;
      border-radius: 5px;
      border: none;
      transition: all .3s ease;

      &:hover {
        background: #CACACA;
      }
    }

    &-track {
      background: #484848;
      border-radius: 5px;
    }
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
}

.unlink-btn {
  border-color: #ff4519 !important;
  font-weight: 600;
  box-shadow: none;
  color: #ff4519 !important;
  background-color: transparent !important;
}

// table row hover background color
:where(.css-dev-only-do-not-override-sqj93m).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>th,
:where(.css-dev-only-do-not-override-sqj93m).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
  background: @light-skyblue !important;
}

table {
  :where(.css-dev-only-do-not-override-sqj93m).ant-checkbox-checked .ant-checkbox-inner {
    background: @primary !important;
  }

  :where(.css-dev-only-do-not-override-sqj93m).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: @primary !important;
  }

  .ant-table-thead th {
    padding: 12px !important;
  }

  .ant-table-tbody {
    .ant-table-cell {
      font-size: 14px !important;
      line-height: 22px;
      padding: 12px !important;
    }

    td.ant-table-selection-column {
      padding: 6px 12px !important;
    }
  }

  th.ant-table-cell.ant-table-selection-column {
    padding: 16px 12px !important;
  }
}

.text-center {
  text-align: center;
}

.ant-select-selector {
  .ant-select-selection-overflow {
    gap: 8px;

    .ant-select-selection-item {
      background: @tag-color;
      border: 0;
    }
  }

  .ant-select-selection-item-remove {
    color: @primary;
  }
}

.formSekeleton {
  .ant-skeleton-button {
    margin-bottom: 10px !important;
  }
}

.ant-form-item-explain {
  color: var(--character-primary-inverse, var(--theme-white, #fff));
  font-size: 1rem;
  margin-left: 3%;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.modal {
  .ant-modal-content {
    border-radius: 10px;
    max-height: 90vh;
    overflow-y: auto;

    input,
    .ant-select-selector {
      border-radius: 15px;
      border: 1px solid @gray;
      background: @white;
    }

    .error,
    .error-class {
      color: @red-color;
      font-size: 12px;
      padding-left: 10px;
    }

    label {
      color: @modal-title-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    .addbutton {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-right: 0;

      .ant-btn-icon {
        margin-inline-end: 5px;
      }
    }

    .ant-upload-drag {
      background: transparent !important;
      border-color: @secondary-blue-btn !important;
      height: 200px;

      .ant-upload-drag-icon {
        .anticon {
          font-size: 40px;
          color: @black-color;
        }
      }
    }

    .title,
    .sub-title {
      color: @modal-title-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .sub-title {
      margin-top: 10px;
    }

    .ant-checkbox-wrapper {
      color: @placeholder-color;
    }

    .drag-list {
      width: 100%;
      border-radius: 38px;
      border: 1px solid @gray;
      background: @white;
      padding: 3px 10px;
      margin-bottom: 10px;

      .anticon {
        margin-left: auto !important;
      }

      p {
        display: flex;
        justify-content: space-between;
        color: @light-gray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        span {
          opacity: 0.45;
        }
      }
    }
  }

  .ant-modal-header {
    margin-bottom: 8px;

    .ant-modal-title {
      color: @modal-title-color;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      word-break: break-word;
    }
  }

  .ant-modal-close {
    color: @modal-title-color;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-around;

    button {
      border-radius: 100px !important;
      width: 50%;
    }
  }
}

.one-btn-footer {
  .ant-modal-footer {
    display: flex;
    justify-content: space-around;

    button {
      border-radius: 100px !important;
      width: 100%;
    }
  }
}

.md-footer {
  .ant-modal-footer {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin: 30px auto 0;

    button {
      border-radius: 100px !important;
      width: 100%;
    }
  }
}

.ant-modal-content .ant-select {
  width: 100%;
}

.iconRotate {
  /* Rotate the icon 90 degrees clockwise */
  transform: rotate(90deg);
  /* Optional: Set the transform origin to center the rotation */
  transform-origin: center center;
}

.ant-popover {
  margin-left: 100px !important;
  z-index: 9999 !important;
}

.display {
  display: flex;
}

.ml-auto {
  margin-left: auto !important;
}


.ant-input:focus {
  border: 0.5px solid @dark-blue-btn !important;
}

// Custom styles
:where(.css-dev-only-do-not-override-15jctj0).ant-layout {
  max-height: 100vh;
}

.side-searchbar {
  // padding: 10px 0;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-modal-root .ant-modal-wrap {
  padding: 15px;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-menu .ant-menu-submenu-arrow {
  right: 0;
  padding: 20px;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-avatar {
  width: 36px;
  height: 36px;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-btn.ant-btn-circle {
  width: 36px;
  height: 36px;
  background: none;
  border-color: rgba(@black-color, 6%);
}

.App {
  padding: 0 8px 16px;

  &.light-theme {
    padding: 0;
    overflow: hidden;
  }
}

:where(.css-dev-only-do-not-override-15jctj0).ant-table-wrapper .ant-table-column-sorter {
  color: @black-color;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
:where(.css-dev-only-do-not-override-15jctj0).ant-radio-button-wrapper {
  background-color: transparent;
}

.dashboard-action-btn-wrap {
  gap: 8px;

  @media (max-width: 1165px) {
    margin-top: 10px;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.my-10 {
  margin-block: 10px;
}

.collapsedsearch {
  color: @white;
}

.toolbar-wrapper {
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-modal .ant-modal-footer {
  margin-top: 25px;
}

.bg-transparent {
  background-color: transparent;
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-15jctj0).ant-btn-primary:not( :disabled):not(.ant-btn-disabled):hover {
  background-color: @dark-blue-btn;
  color: @white;
  border-color: @dark-blue-btn;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: @main-layout-bg !important;
  background-color: @main-layout-bg !important;
}

.one-btn-footer {
  .ant-modal-footer {
    margin-top: 12px !important;
  }
}

// :where(.css-dev-only-do-not-override-1qbztl0).ant-drawer {
//   z-index: 9 !important;
// }

:where(.css-dev-only-do-not-override-1qbztl0).ant-drawer {
  z-index: 999;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-drawer .ant-drawer-header {
  padding: 28px 24px 8.4px !important;
  border: 1px solid @gray;

}

:where(.css-dev-only-do-not-override-1qbztl0).ant-image .ant-image-img {
  width: auto;
  height: 200px;
}