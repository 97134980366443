@import "Config/ThemeVariables.less";

.blueBtn {
  color: @primary;
}
.redBtn {
  color: @red-delete-color;
}
.upload-doc {
  .upload-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 280px;
    border: 1.5px dashed @blue-btn;
    padding: 20px;
  }
  .upload-btn {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 0;
    box-shadow: none;
  }
  .anticon-upload {
    font-size: 31px;
  }
}
.delete-modal {
  .delete-icon {
    background: @delete-icon-bg;
    color: @red-delete-color;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    font-size: 23px;
    margin-bottom: 18px;
  }
  .info-icon {
    background: rgba(24, 144, 255, .15);
    color: @primary;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    font-size: 23px;
    margin-bottom: 18px;
  }
  p {
    color: @text-dark;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.full-tabs {
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
      margin: 0;
    }
    .ant-tabs-ink-bar {
      width: 100%;
    }
  }
}
